.processes-table {
    .info-col {
        min-width: 431px;
    }

    .status-col {
        text-align: center;

        .Polaris-Badge {
            text-transform: capitalize;
            width: 81px;
            justify-content: center;
        }
    }
}

@media all and (max-width: 998px) {
    .processes-table {
        .info-col {
            min-width: unset;
        }
    }
}
