.login-page {
    height: 100vh;
    background-color: white;
    display: flex;
    align-items: center;

    .logo {
        margin-bottom: 1rem;
        background-image: url('/img/logo.svg');
        width: 293px;
        height: 48px;
    }

    .Polaris-FormLayout {
        min-width: 300px;
        margin-top: 0;

        .Polaris-TextField__Input {
            padding: 15px 40px 15px 18px;
        }

        .Polaris-Connected__Item:not(.Polaris-Connected__Item--primary) {
            display: inline-block;
            position: absolute;
            right: 18px;
            top: 18px;
            z-index: 30;
        }

        .Polaris-Connected__Item--primary:not(:last-child) * {
            border-top-right-radius: 3px !important;
            border-bottom-right-radius: 3px !important;
        }
    }
}
