.app-card {
    width: 458px;
    max-width: 100%;

    .Polaris-Card {
        & > .Polaris-Stack {
            & > .Polaris-Stack__Item:last-child {
                flex-grow: 1;
                width: 50%;
            }
        }
    }

    .Polaris-Heading {
        border-bottom: 1px solid #DFE3E8;
        padding: 0 1.2rem;
        display: flex;
        align-items: center;
        height: 4.2rem;

        .Polaris-Thumbnail {
            display: inline-block;
            height: 1.5rem;
            width: 1.5rem;
            min-width: unset;
            border-radius: 0;
            margin-right: 0.5rem;
        }
        .Polaris-Badge {
            margin-left: auto;
            text-align: center;
        }
    }

    .tabs {
        width: 188px;

        .tab {
            padding: 12px 20px;
            white-space: nowrap;
            background-color: #F4F6F8;
            border: 1px solid #DFE3E8;
            border-left: none;
            border-top: none;
            cursor: pointer;
            transition: 0.3s;
            &:last-child {
                border-bottom: none;
                border-bottom-left-radius: 0.5rem;
            }

            &.active {
                background-color: white;
                border-left: 3px solid #5C6AC4;

                .tab-title {
                    color: #5C6AC4;
                }
            }

            .tab-title {
                display: block;
                color: #637381;
            }
        }
    }

    .tab-content {
        padding: 0.8rem 1.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .tab-title {
            text-align: center;
        }
    }

    .tab-title {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 0.3rem;
    }
}

@media all and (max-width:640px) {
    .app-card {
        .Polaris-Stack {
            flex-direction: column;
            > .Polaris-Stack__Item:nth-of-type(2) {
                width: stretch !important;
            }
        }
        .tabs {
            width: 100%;
            .tab {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-right: none;
                &.active span {
                    margin-left: -3px;
                }
            }
        }
    }

    .Polaris-Layout__Section:last-of-type {
        overflow: hidden;
    }
}
