.invite {
    .Polaris-Layout__AnnotationContent {
        display: flex;
        align-items: center;

        & > div {
            width: 100%;
        }
    }
}
