.teams {
    .Polaris-Layout__AnnotatedSection {
        .Polaris-Layout__AnnotationDescription {
            .skeleton-annotated-text {
                visibility: hidden;
                margin-top: -20px;
            }
        }

        .Polaris-Layout__AnnotationContent {
            .Polaris-Stack+.Polaris-Stack {
                padding-top: 20px;
            }
        }
    }

    #invited-tab-panel .Polaris-Stack__Item--fill {
        width: 65%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.user-page {
    .Polaris-Layout__AnnotatedSection {
        .Polaris-Layout__Annotation {
            padding-top: 0;
        }
    }

    .save-button-wrapper {
        text-align: -webkit-right;
        text-align: -moz-right;
    }
}
