.app-statuses {
    & > .Polaris-Stack {
        & > .Polaris-Stack__Item {
            margin-left: 2.1rem;
        }
    }

    .app-status {
        .Polaris-Stack__Item {
            &:last-child {
                margin-left: 0.5rem;
            }
        }
    }
}
