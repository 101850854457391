@import '@shopify/polaris/build/esm/styles.css';

@import "components/app/app-card";
@import "components/app/app-item";
@import "components/app/app-status";
@import "components/loader";
@import "components/notification-settings";
@import "components/processes";
@import "components/statuses-bar";
@import "components/top-bar";

@import "pages/apps";
@import "pages/info-for-users";
@import "pages/login";
@import "pages/instances";
@import "pages/teams";
@import "pages/invite";

body[data-lock-scrolling] {
    overflow-y: hidden;
}

p pre {
    display: inline;
    font-weight: bold;
}

.Polaris-Navigation a.active {
    pointer-events: none;
}

.Polaris-Stack--alignmentCenter .switch {
    margin-top: 5px;
}

.Polaris-TextField__Input[type="number"] {
    width: 100px;
}

.Polaris-Modal-Dialog__Modal {
    max-width: 740px;
}
