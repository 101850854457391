$header-height: 6.5rem;

.guest-header {
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 0 3rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    & + .Polaris-Layout {
        padding-top: $header-height;
    }
    .guest-header__logo {
        width: 11.5rem;
        height: 1.9rem;
        background-image: url('/img/logo.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.info_for_users-page {
    .apps-list {
        width: 100%;
        margin-bottom: 1.25rem;

        & > .Polaris-Stack {
            margin-left: 0;

            & > .Polaris-Stack__Item {
                width: 50%;
                margin-left: 0;
            }
        }
    }

    .all-ok-status {
        padding: 1.25rem 0;
        text-align: center;

        .Polaris-Icon {
            margin-bottom: 1.5rem;
            height: 6rem;
            width: 6rem;
        }
    }
    .subtext-notice {
        text-align: center;
        margin: 1.25rem 0.65rem;
    }
}

@media all and (max-width: 768px) {
    .info_for_users-page {
        .apps-list {
            & > .Polaris-Stack {
                flex-direction: column;

                & > .Polaris-Stack__Item {
                    width: 100%;
                }
            }
        }

        .app-statuses {
            & > .Polaris-Stack {
                font-size: 0.75rem;

                & > .Polaris-Stack__Item {
                    margin-left: 1rem;
                }
            }
        }
    }
}
