.ondemand-units,
.reserved-units {
    margin: 1.25rem;
    text-align: center;

    .unit {
        display: block;
        margin: 0.75rem 0 1.25rem;
        font-weight: bold;
        font-size: 3rem;
    }

    &.exceeded .unit {
        color: red;
    }
}
