.apps-page {
    .search {
        .Polaris-TextField {
            margin-bottom: 0.5rem;
        }
    }
}

@media all and (max-width: 1235px) {
    .apps-page {
        .Polaris-Layout__Section > .Polaris-Stack {
            justify-content: center;
        }
    }
}

.apps-list__add {
    margin-left: 1.25rem;
    margin-top: 0.65rem;
}

.pie-chart {
    margin-top: 1.9rem;
    width: 100%;
    height: 100%;
}
