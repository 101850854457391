.emails-list {
    .Polaris-ResourceList__ItemWrapper {
        overflow: visible;

        .Polaris-ResourceItem {
            &:hover {
                background-image: none;
            }

            &.Polaris-ResourceItem--focused {
                box-shadow: none;
            }

            .Polaris-ResourceItem__Container {
                padding: 0.75rem 1rem;

                .Polaris-Stack {
                    .Polaris-Stack__Item {
                        &:first-child {
                            margin-left: 0;
                        }

                        .Polaris-Button {
                            margin-top: 0;
                        }

                        .Polaris-TextField__Input {
                            width: 457px;
                        }
                    }
                }
            }
        }
    }

    &__add .Polaris-Button {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media all and (max-width: 998px) {
    .emails-list {
        .Polaris-ResourceList__ItemWrapper {
            .Polaris-ResourceItem {
                .Polaris-ResourceItem__Container {
                    .Polaris-Stack {
                        .Polaris-Stack__Item {
                            .Polaris-TextField__Input {
                                width: 230px;
                            }
                        }
                    }
                }
            }
        }
    }
}
