.app-item {
    border-bottom: 1px solid #C4CDD5;
    padding: 1rem 0;

    .app-title {
        margin-bottom: 0.5rem;
        & > .Polaris-Stack {
            & > .Polaris-Stack__Item:last-child {
                margin-left: 0;
            }
        }

        .Polaris-Thumbnail {
            height: 1.5rem;
            width: 1.5rem;
            min-width: unset;
            border-radius: 0;
            margin-right: 1rem;
        }

        .Polaris-Heading {
            line-height: 1.5rem;
        }
    }

    .app-status {
        .Polaris-Stack__Item {
            &:first-child {
                width: 1.5rem;
            }
        }
    }
}
